import React, { useEffect, useMemo } from 'react'
import { useFirestoreDocData } from 'reactfire';
import { doc } from '@firebase/firestore'
import { Container, HStack, Stack, Text, Box } from '@chakra-ui/layout';
import { navigate } from 'gatsby-link';

import useResult from './useResult'
import useScores from '../useScores'
import HeartAnimals from './heartAnimals';
import Title from './Title';
import Radar from './radar';
import DrawButton from '../../../components/DrawButton';
import CenterSpinner from '../../../components/CenterSpinner';
import Wishes from './Wishes';
import Subscribe from './Subscribe';
import useGatsbySearchParams from '../../../utils/useGatsbySearchParams';
import withFirestoreData from '../../../utils/withFirestoreData'

const DualResult = ({ results, scores, myAnswerId, otherResultId }) => {
  const isSame = results && (results[0]?.name === results[1]?.name)
  const mainResult = results[isSame ? 0 : 1]
  const person = isSame ? '你們' : '他'
  useEffect(() => {
    if (!results[0] || !results[1]) navigate('/q/love-languages/')
  }, [results])
  if (!results[0] || !results[1]) return null
  return (
    <>
      <HeartAnimals
        results={results}
        mainResult={mainResult}
        person={person}
        isLove
        more={results[1]?.result[isSame ? 'same' : 'diff']}
      />
      <Container pt="1.75rem" pb="2.25em" as={Stack} spacing="4">
        <Title
          text="你們的愛之語全分析"
          isLove
          color="love.title"
        />
        <Radar isLove mt="0.625em" scores={scores[0]} otherScores={scores[1]} color="love.primary" />
        <HStack justifyContent="center">
          <HStack>
            <Box w="2em" h="1.25em" bg="love.primary" opacity="0.6" />
            <Text fontSize="sm">你的結果</Text>
          </HStack>
          <HStack>
            <Box w="2em" h="1.25em" border="3px solid" borderColor="love.primary" />
            <Text fontSize="sm">對方的結果</Text>
          </HStack>
        </HStack>
        <Stack px="4" alignItems="center" pt="4">
          <DrawButton state={{ isDualView: true, myAnswerId }} to={`/q/love-languages/lover/result/?key=${otherResultId}`}>看對方的結果</DrawButton>
          <DrawButton state={{ isDualView: true, myAnswerId }} to={`/q/love-languages/lover/result/?key=${myAnswerId}`}>看自己的結果</DrawButton>
        </Stack>
      </Container>
      <Box bg="white" py="1.875em">
        <Wishes isLove />
      </Box>
      <Subscribe />
    </>
  )
}

const DualResultData = ({ ids, ...props }) => {
  const firestoreRefA = props.firestore && doc(props.firestore, 'answers', ids[0])
  const firestoreDataA = useFirestoreDocData(firestoreRefA);
  const scoresA = useScores(firestoreDataA?.data?.answers)
  const resultA = useResult(scoresA, props.pageContext.questions)
  const firestoreRefB = props.firestore && doc(props.firestore, 'answers', ids[1])
  const firestoreDataB = useFirestoreDocData(firestoreRefB);
  const scoresB = useScores(firestoreDataB?.data?.answers)
  const resultB = useResult(scoresB, props.pageContext.questions)
  return firestoreDataA.status === 'loading' || firestoreDataB.status === 'loading'
    ? <CenterSpinner />
    : <DualResult scores={[scoresA, scoresB]} results={[resultA, resultB]} {...props} />
}

const Invitation = ({ firestoreData, firestoreRef, ...props }) => {
  const myAnswerId = useGatsbySearchParams(props.location, 'key')
  const ids = useMemo(() => {
    if (!firestoreData.data) return []
    const { answerId, inviteeId } = firestoreData.data
    if (myAnswerId === answerId) return [answerId, inviteeId]
    if (myAnswerId === inviteeId) return [inviteeId, answerId]
    return []
  }, [firestoreData.data])
  // const scores = useScores(firestoreData?.data?.answers)
  // const resultData = useResult(scores, pageContext.questions)
  return firestoreData.status === 'loading' || ids.length === 0 ? <CenterSpinner /> : (
    <DualResultData
      ids={ids}
      myAnswerId={myAnswerId}
      otherResultId={ids.find(id => id !== myAnswerId)}
      {...props}
    />
  )
}

const EnhancedInvitation = withFirestoreData(Invitation)

const HandleFiresoreData = (props) => {
  const invitationId = useGatsbySearchParams(props.location, 'id')
  return <EnhancedInvitation firestorePath={`invitations/${invitationId}`} invitationId={invitationId} {...props} />
}

export default HandleFiresoreData
